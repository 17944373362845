

















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {
  GetFrontendPageResponse,
  PersistentModule,
  PostFrontendPage
} from "~/shared/MyService.dtos";
import {SessionStore} from "~/utils/store-accessor";
import {v4 as uuid} from 'uuid';
import draggable from 'vuedraggable';

@Component({
  components: {
    draggable
  }
})
export default class EditablePage extends Vue {
  @Prop({type: String})
  readonly uniqueIdentifier;

  @Prop({type: String, default: 'secondary'})
  variant;

  @Prop({type: Boolean})
  vertical;

  drag = false;
  editMode = false;
  modules: PersistentModule[] = [];
  origModules: PersistentModule[] = [];
  promise: Promise<GetFrontendPageResponse> = null;

  get editable (): boolean {
    return SessionStore.isAdmin
  }

  addText(): void {
    this.modules.push({
      type: 'text',
      uniqueIdentifier: uuid()
    } as PersistentModule)
  }

  addTwoColumns(): void {
    this.modules.push({
      type: 'tow-columns',
      modules: [
        {
          type: 'image',
          uniqueIdentifier: uuid()
        },
        {
          type: 'text',
          uniqueIdentifier: uuid()
        }
      ],
      uniqueIdentifier: uuid()
    } as PersistentModule)
  }

  created(): void {
    this.promise = this.$api.getFrontendPage(this.uniqueIdentifier).then(data => {
      if (data.modules) {
        this.modules = data.modules;
        this.origModules = data.modules;
      }
      return data;
    });
  }

  postPage(): void {
    this.$api.saveFrontendPage(new PostFrontendPage({
      uniqueIdentifier: this.uniqueIdentifier,
      modules: [...this.modules]
    })).then(() => {
      this.$bvToast.show('success');
      this.editMode = false;
      this.origModules = this.modules;
    }).catch(() => {
      this.$bvToast.show('error');
    })
  }

  deleteModule(uniqueIdentifier: string): void {
    let modules = [...this.modules];
    const index = modules.findIndex(x => x.uniqueIdentifier === uniqueIdentifier);
    modules.splice(index, 1);
    this.modules = modules;
  }

  updateModule(uniqueIdentifier: string, module: PersistentModule): void {
    let modules = [...this.modules];
    const index = modules.findIndex(x => x.uniqueIdentifier === uniqueIdentifier);
    modules.splice(index, 1, module);
    this.modules = modules;
  }
}
