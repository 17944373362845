




































































































































































































































































































































































































































import {Component, Vue} from 'nuxt-property-decorator'
import {BlogPost, GetBlogPostsResponse} from '~/shared/MyService.dtos';
import CounterBubble from "~/components/CounterBubble.vue";
import IndexContentBox from "~/components/IndexContentBox.vue";
import EditablePage from "~/components/EditablePage.vue";
import PopUp from "~/components/PopUp.vue";
import KeyValSubStore from "~/entities/KeyValSubStore";
import {KeyValStore} from "~/utils/store-accessor";

@Component({
  components: {PopUp, EditablePage, IndexContentBox, CounterBubble},
  head() {
    return {
      title: 'Jobs, Praktika, Tierarztpraxis oder Nachfolger finden - job.vet',
      meta: [
        { hid: 'description', name: 'description', content: this.description },
        { hid: 'og:description', property: 'og:description', content: this.description }
      ]
    }
  }
})
export default class Index extends Vue {
  blogPromise: Promise<GetBlogPostsResponse>;
  description = 'Auf unserer Karriereplattform für Tiermediziner bringen wir Arbeitnehmer und Arbeitgeber zusammen! Finden Sie jetzt Ihr Match in unserem innovativen Agenten.';
  wizardStore: KeyValSubStore = new KeyValSubStore(KeyValStore, 'wizard-stelle');

  created(): void {
    this.blogPromise = this.$api.getBlogPosts()
  }

  mounted() {
    this.wizardStore.clearAll();
  }

  getFeaturedMedia(post: BlogPost): string {
    return post.fImg_Url;
  }
}
