import KeyVal from "~/store/keyval";

export default class KeyValSubStore {
  constructor(
    private _store: KeyVal,
    public namespace: string
  ) {
  }

  public getValue(key: string) {
    return this._store.data[`${this.namespace}:${key}`];
  }

  public setValue({key, value}: {key: string, value: any}) {
    this._store.setValue({ key: `${this.namespace}:${key}`, value, source: key })
  }

  public setValues(data: {[key: string]: any}) {
    const keys: {[key: string]: string} = {};
    const values: {[key: string]: string} = {};
    Object.keys(data).forEach(key => {
      keys[`${this.namespace}:${key}`] = key;
      values[`${this.namespace}:${key}`] = data[key];
    });
    this._store.setKeys(keys);
    this._store.setValues(values);
  }

  public deleteKey(key: string) {
    this._store.deleteKey(`${this.namespace}:${key}`);
  }

  public clearAll() {
    const namespacedKeys: string[] = Object.keys(this._store.data).filter(x => x.startsWith(`${this.namespace}:`));
    for (const key of namespacedKeys) {
      this._store.deleteKey(key);
    }
  }

  public get data() {
    const namespacedKeys: string[] = Object.keys(this._store.data).filter(x => x.startsWith(`${this.namespace}:`));
    return namespacedKeys.reduce((map, namespacedKey) => {
      map[this._store.keys[namespacedKey]] = this._store.data[namespacedKey];
      return map;
    }, {});
  }

  public set data(data) {
    const othersKeys = Object.keys(this._store.data).filter(x => !x.startsWith(`${this.namespace}:`))
    const othersData = othersKeys.reduce((map, x) => {
      map[x] = this._store.data[x]
      return map;
    }, {})
    const values = Object.keys(data).reduce((map, x) => {
      map[`${this.namespace}:${x}`] = data[x];
      return map;
    }, {})
    this._store.setData({
      ...values,
      ...othersData
    })
    const keys = Object.keys(data).reduce((map, x) => {
      map[`${this.namespace}:${x}`] = x;
      return map;
    }, {});
    this._store.setKeys(keys)
  }

  public get hasKey() {
    return (key: string) => {
      return Object.keys(this.data).includes(key)
    }
  }

  public get hasAnyKeyIn() {
    return (keys: string[]) => {
      return Object.keys(this.data).filter(x => keys.includes(x)).length > 0;
    }
  }
}
