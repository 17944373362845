























































































import {Component, Vue, Watch} from 'vue-property-decorator';
import gsap from 'gsap';

@Component
export default class CounterBubble extends Vue {
  value = 0;
  totalItems = 0;

  mounted(): void {
    this.loadTotalCount();
  }

  async loadTotalCount(): Promise<void> {
    const result = await this.$api.countAllOffers()
    this.totalItems = result.totalItems
  }

  get fixedValue(): string {
    return this.value.toFixed();
  }

  @Watch('totalItems')
  handle(value: number): void {
    if (this.$device.isMobile) {
      this.value = value;
    } else {
      gsap.to(this.$data, {
        duration: 3,
        value: value,
        ease: 'power1'
      });
    }
  }
}
