



























import {Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class IndexContentBox extends Vue {
  @Prop()
  contentIdentifier;

  @Prop()
  imgAlt;

  @Prop()
  imgSrc;

  @Prop()
  to;
}
